<template>
  <div class="login-modal address-modal modal fade" tabindex="-1"
       aria-labelledby="addressModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content themeBlue-before">
        <div class="modal-header justify-content-center mx-3">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title fontsize14 font-weight-bold text-color-444" id="loginModalLabel">
            {{ mode == 'edit' ? 'ویرایش آدرس' : 'ثبت آدرس' }}
          </h5>
        </div>

        <div class="modal-body px-3 pb-3">
          <form @submit.prevent="submit()">
            <div class="row mx-0">
              <div class="col-md-6 px-1 mt-2">
                <multiselect v-if="$store.state.header.provinces" class="text-right"
                             v-model="selected_province" :options="$store.state.header.provinces" track-by="id"
                             label="name" :searchable="true" :close-on-select="true" :show-labels="false"
                             placeholder="استان را انتخاب کنید (الزامی)" @input="loadCities">
                </multiselect>
              </div>
              <transition name="fade">
                <div v-if="selected_province!= null && $store.state.header.cities != ''"
                     class="col-md-6 px-1">
                  <multiselect class="text-right mt-2" v-model="realAddress.city"
                               :options="$store.state.header.cities" track-by="id" label="name"
                               :searchable="true" :close-on-select="true" :show-labels="false"
                               placeholder="شهر را انتخاب کنید (الزامی)">
                  </multiselect>
                </div>
              </transition>
              <div class="col-md-6 px-1">
                <input type="text" class="w-100 mt-2 p-2" v-model="realAddress.first_name"
                       placeholder="نام (الزامی)">
              </div>
              <div class="col-md-6 px-1">
                <input type="text" class="w-100 mt-2 p-2" v-model="realAddress.last_name"
                       placeholder="نام خانوادگی (الزامی)">
              </div>
              <div class="col-md-6 px-1">
                <input type="text" class="w-100 mt-2 p-2" v-model="realAddress.mobile"
                       placeholder="تلفن همراه (الزامی)">

              </div>
              <div class="col-md-6 px-1">
                <input type="text" class="w-100  mt-2 p-2" v-model="realAddress.postal_code"
                       placeholder="کدپستی (الزامی)">

              </div>
              <!-- <div class="col-md-6 px-1">
                <input type="text" class="w-100  mt-2 p-2" v-model="realAddress.national_code"
                       placeholder="کد ملی (الزامی)">

              </div> -->
              <div class="col-md-6 px-1">
                <input type="text" class="w-100  mt-2 p-2" v-model="realAddress.telephone"
                       placeholder="تلفن ثابت (اختیاری)">

              </div>
              <div :class="selected_province!= null && $store.state.header.cities != '' ? 'col-md-12' : 'col-md-6'"
                   class="px-1">
                <input type="text" class="w-100  mt-2 p-2" v-model="realAddress.plaque"
                       placeholder="پلاک (اختیاری)">

              </div>
              <div class="col-md-12 px-1">
                                <textarea class="w-100 mt-2 p-2" v-model="realAddress.address"
                                          placeholder="آدرس (الزامی)"></textarea>
              </div>
            </div>
            <input :disabled="disabled" type="submit"
                   class="p-2 mt-2 w-100 weight-bold text-color-themeBlue"
                   :value="mode == 'edit' ? 'ویرایش آدرس' : 'ثبت آدرس'">
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
function clone(object) {
  let newObj = {};
  for (let [index,value] of Object.entries(object)) {
    newObj[index] = value;
  }

  return newObj
}

export default {
  name: 'AddressModal',
  props: ['address', 'mode', 'shouldLoadProvinces'],
  data() {
    return {
      disabled: false,
      selected_province: null,
      edit: null,
      realAddress: this.address ? clone(this.address) : {}
    }
  },
  watch: {
    address: function (address) {
      if (address.city) {
        this.selected_province = this.$store.state.header.provinces.filter(item => item.id == address.city.province_id)[0]
        this.loadCities(this.$store.state.header.provinces.filter(item => item.id == address.city.province_id)[0])
      } else {
        this.selected_province = null
        this.$store.commit('header/setCities', null)
      }
      this.realAddress = clone(address)
    }
  },
  created() {
    this.loadProvinces()
  },
  methods: {
    loadProvinces() {
      if (!this.$store.state.header.provinces && this.shouldLoadProvinces) {
        this.$axios.get('/api/provinces/' + 102)
            .then(response => {
              this.$store.commit('header/setProvinces', response.data.data)
            })
      }
    },
    loadCities(event) {
      if (event != null) {
        this.$store.commit('header/setCities', event.cities)
      }
    },
    submit() {
      this.mode == 'new' ? this.newAddress() : ''
      this.mode == 'edit' ? this.editAddress() : ''
    },
    newAddress() {
      let item = {
        ...this.realAddress
      };
      this.disabled = true
      this.$axios.post('/api/profile/address', {
        city_id: item.city != null ? item.city.id : '',
        first_name: item.first_name,
        last_name: item.last_name,
        mobile: item.mobile,
        telephone: item.telephone,
        postal_code: item.postal_code,
        plaque: item.plaque,
        // national_code: item.national_code,
        address: item.address,
        latitude: '',
        longitude: '',
      })
          .then(response => {
            this.$root.success_notification(response.data.message)
            this.$store.commit('header/setAddress', response.data.data)
            if (this.$parent._data.selected_address == null) {
              this.$parent._data.selected_address = response.data.data
            }
            item = {
              first_name: '',
              last_name: '',
              mobile: '',
              telephone: '',
              postal_code: '',
              plaque: '',
              // national_code: '',
              address: ''
            }
            this.selected_province = null
            this.$('#addressModalMobile').modal('hide')
            this.$('#addressModalDesktop').modal('hide')
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })

      this.updateData(item)
    },
    editAddress() {
      let item = {
        ...this.realAddress
      };
      this.disabled = true
      this.$axios.patch('/api/profile/address/' + item.id, {
        city_id: item.city != null ? item.city.id : '',
        first_name: item.first_name,
        last_name: item.last_name,
        mobile: item.mobile,
        telephone: item.telephone,
        postal_code: item.postal_code,
        plaque: item.plaque,
        // national_code: item.national_code,
        address: item.address,
        latitude: '',
        longitude: '',
      })
          .then(response => {
            this.$root.success_notification(response.data.message)
            this.$store.commit('header/setEditAddress', response.data.data)
            this.$('#addressModalMobile').modal('hide')
            this.$('#addressModalDesktop').modal('hide')
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })

      this.updateData(item)
    },
    updateData(item) {
      this.$emit("input", item);
    }
  },
}
</script>
